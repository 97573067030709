import React from "react";
import AOS from 'aos';
import { graphql } from 'gatsby';
// import Img from "gatsby-image";

import PrimaryLayout from '../layouts/PrimaryLayout';
import SEO from '../layouts/SEO';


export default function CustomerExpericence({data}) {
  const page = data.wordpressPage;
  if (typeof window !== 'undefined') {
    AOS.init({ duration : 800});
  }
  return (
    <PrimaryLayout>
      <SEO 
        title={page.acf.seo_title}
        description={page.acf.seo_description}
        keywords={page.acf.seo_keywords}
      />
      <div class="container-fluid">
        <div class="row p-0 m-0">
          <div class="container main-container-padding-t-5 main-container-padding-b-2">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="header-text-container"
                >
                  <div data-aos="fade-up" className="vertical-center" dangerouslySetInnerHTML={{__html: page.content}} />
                </div>
              </div>
              <div
                class="col-md-6"
              >
                <img
                  data-src={page.featured_media && page.featured_media.source_url}
                  class="header-image lazyload"
                  alt="Technology Services | Top Digital Marketing Agency | Alkye"
                   data-aos="fade-up"
                />
                {/* <Img 
                  fixed={page.featured_media.localFile.childImageSharp.fixed}
                  alt={page.acf.seo_title}
                  /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {page.acf.service_section_content && page.acf.service_section_content.map((section, index) => (
        <div class={`container-fluid ${section.section_mode}`} key={index} id={section.section_title.replace(/[^a-zA-Z ]/g, "").split(' ').join('-')}>
            <div class="row p-0 m-0">
              <div class="container main-container-padding-t-2 main-container-padding-b-2">
                <div class="row">
                  <div class="col-md-1">
                    <h4 data-aos="fade-up">{index + 1}.0/</h4>
                  </div>
                  <div class="col-md-11">
                    <div
                      class="header-text-container"
                    >
                      <div class="vertical-center">
                        <h2 data-aos="fade-up" dangerouslySetInnerHTML={{__html: section.section_title}} />  
                        <p data-aos="fade-up" class="pt-3 text-primary-color" dangerouslySetInnerHTML={{__html: section.section_content}} />
                        <div data-aos="fade-up" className="text-primary-color lead pt-3" dangerouslySetInnerHTML={{__html: section.service_links_list_title}}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      ))}
    </PrimaryLayout>
  )
}


export const query = graphql`
{
  wordpressPage(wordpress_id: {eq: 228}) {
    content
    featured_media {
      source_url
    }
    acf {
      service_section_content {
        section_content
        section_mode
        section_title
        service_links_list_title
      }
      seo_title
      seo_keywords
      seo_description
    }
  }
}
`